import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../utils/SectionProps";
import SectionHeader from "../components/sections/partials/SectionHeader";
import { useState, useEffect } from "react";
import axios from "axios";
import { ContactForm } from "../components/custom/ContactForm";
import { Grid, Paper } from "@mui/material";
import CustomHero from "../components/sections/CustomHero";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const ContactUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: " ",
    paragraph: "",
  };

  const [data, setData] = useState({});

  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "ContactUs",
        },
      })
      .then((response) => {
        setData({
          image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
          text: response.data.text1,
          additionalContact: {
            address:
              " Immeuble Jean Paul II, Ghadir",
            tel: "09- 860988",
            mob: "09- 860988",
            email: "info-ghadir@padcenter.org",
            hours: "Autres Horaires",
            mapSrc:
              // "https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d52889.35523404294!2d35.678864377424425!3d34.05452271196382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x151f451bc1e1708b%3A0x92533ba5c40ac0c3!2sCentre%20P.A.D.%20(Pr%C3%A9vention%20Accompagnement%20D%C3%A9veloppement)%2C%20Rue%20Saints%20Sarkis%20et%20Bakos%2C%20Ghbaleh!3m2!1d34.054458499999996!2d35.72015!5e0!3m2!1fen!2slb!4v1725432879083!5m2!1fen!2slb",
             ' https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3308.476161422249!2d35.634619375713434!3d33.980301273184274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU4JzQ5LjEiTiAzNcKwMzgnMTMuOSJF!5e0!3m2!1sen!2slb!4v1732753385665!5m2!1sen!2slb',
            },
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <section {...props} className={outerClasses}>
      <CustomHero title="Contactez-nous" image={data?.image} page="Contact" />

      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <div className="textwidget">
                  <aside className="widget mtphr-contact-widget">
                    <h3 className="widget-title">Ghbaleh</h3>
                    <table>
                      <tbody>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Adresse :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            Près de l'église Saint-Sarkis et Saint-Bakhos,
                            Ghbaleh, Liban
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Téléphone:
                          </td>
                          <td className="mtphr-contact-widget-description">
                            09- 860988
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Mobile :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            09- 860988{" "}
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Email :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            <a href="mailto:stelielab@gmail.com">
                              <h6 style={{ margin: 0 }}> info-ghbaleh@padcenter.org</h6>
                            </a>
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Lundi, Mardi, Jeudi :
                          </td>
                          <td className="mtphr-contact-widget-description">
                          de 15h 00 à 19h30
                          </td>
                        </tr>
                        {/* <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Samedi :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            7 h à 13 h
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </aside>
                  <div className="map-container" style={{ marginTop: "20px" }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d52889.35523404294!2d35.678864377424425!3d34.05452271196382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x151f451bc1e1708b%3A0x92533ba5c40ac0c3!2sCentre%20P.A.D.%20(Pr%C3%A9vention%20Accompagnement%20D%C3%A9veloppement)%2C%20Rue%20Saints%20Sarkis%20et%20Bakos%2C%20Ghbaleh!3m2!1d34.054458499999996!2d35.72015!5e0!3m2!1fen!2slb!4v1725432879083!5m2!1fen!2slb"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <div className="textwidget">
                  <aside className="widget mtphr-contact-widget">
                    <h3 className="widget-title">Ghadir</h3>
                    <table>
                      <tbody>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Adresse :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            {data.additionalContact?.address || "N/A"}
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Téléphone:
                          </td>
                          <td className="mtphr-contact-widget-description">
                            {data.additionalContact?.tel || "N/A"}
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Mobile :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            {data.additionalContact?.mob || "N/A"}
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Email :
                          </td>
                          <td className="mtphr-contact-widget-description">
                            <a
                              href={`mailto:${
                                data.additionalContact?.email || "#"
                              }`}
                            >
                              <h6 style={{ margin: 0 }}>
                                {data.additionalContact?.email || "N/A"}
                              </h6>
                            </a>
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                          Mercredi, Vendredi :
                          </td>
                          <td className="mtphr-contact-widget-description">
                          de 15h00 à 19h30
                          </td>
                        </tr>
                        <tr className="mtphr-contact-widget-info">
                          <td className="mtphr-contact-widget-title">
                            Samedi :
                          </td>
                          <td className="mtphr-contact-widget-description">
                          de 9h00 à 12h30
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </aside>
                  <div className="map-container" style={{ marginTop: "20px" }}>
                    <iframe
                      src={data.additionalContact?.mapSrc || ""}
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>

          {/* Get in Touch Form */}
          <Grid container justifyContent="center" style={{ marginTop: "40px" }}>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  width: "100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  margin: "0 auto",
                }}
              >
                <h3 className="widget-title">Contactez-nous</h3>
                <ContactForm />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;
