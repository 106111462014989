import { Button, TextField } from "@mui/material";
import React from "react";
import { useFormControls } from "./ContactFormControls";

const inputFieldValues = [
  {
    name: "full_name",
    label: "Nom Complet",
    id: "full_name",
  },
  {
    name: "email",
    label: "Email",
    id: "email",
  },
  {
    name: "phone",
    label: "Téléphone",
    id: "phone",
  },
  {
    name: "message",
    label: "Votre Message",
    id: "message",
    multiline: true,
    rows: 5,
  },
];

export const ContactForm = () => {
  const { handleInputValue, handleFormSubmit, formIsValid, errors, values } =
    useFormControls();

  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      {inputFieldValues.map((inputFieldValue, index) => {
        return (
          <TextField
            style={{ marginBottom: 10 }}
            key={index}
            onChange={handleInputValue}
            onBlur={handleInputValue}
            value={values[inputFieldValue.name]} // Bind the value here
            name={inputFieldValue.name}
            label={inputFieldValue.label}
            error={Boolean(errors[inputFieldValue.name])} // Check for errors
            multiline={inputFieldValue.multiline ?? false}
            fullWidth
            rows={inputFieldValue.rows ?? 1}
            autoComplete="none"
            helperText={errors[inputFieldValue.name]}
          />
        );
      })}
      <Button
        variant="contained"
        type="submit"
        color="inherit"
        disabled={!formIsValid()}
      >
        Envoyer le Message
      </Button>
    </form>
  );
};
